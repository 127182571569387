angular.module('auth', [])


    .constant('authEvents', {
        login: 'authenticationService.UserLogin',
        logout: 'authenticationService.UserLogout'
    })

    .factory('authenticationService', ['$http', '$q', '$rootScope', '__env', 'authEvents', 'authenticationInfo', function ($http, $q, $rootScope, __env, authEvents, authenticationInfo) {

        var serviceUrl = __env.apiUrl;

        function login(loginData) {

            var data = "grant_type=password&username=" + loginData.userName + "&password=" + loginData.password;

            return $http.post(serviceUrl + 'auth/login', data, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
                .then(function (response) {
                    var response = response.data;

                    authenticationInfo.isAuthenticated = true;
                    authenticationInfo.token = response.access_token;
                    authenticationInfo.userName = loginData.userName;
                    getRol()
                        .then(function (data) {
                            for (var i in data[0]) {
                                if (i.toString() == 'value') {   //subject, type, value
                                    authenticationInfo.rol = data[0][i];
                                    authenticationInfo.save();
                                    break;
                                }
                            }
                        })
                        .catch(function (err) {
                            authenticationInfo.rol = "";
                        });

                    authenticationInfo.save();

                    $rootScope.$broadcast(authEvents.login);
                }).catch(function (response) {
                    logout();
                });
        };

        function getRol() {
            var defered = $q.defer();
            var promise = defered.promise;

            $http.get(serviceUrl + 'claims/rol')
                .then(function (response) {
                    var data = response.data;
                    defered.resolve(data);
                })
                .catch(function (err) {
                    defered.reject(err.data);
                });
            return promise;
        };

        function logout() {
            authenticationInfo.clear();

            $rootScope.$broadcast(authEvents.logout);
        };

        return {
            login: login,
            logout: logout
        }
    }]);