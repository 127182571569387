
var editarUsuarioPropio = angular.module('usuariosAdmin');

editarUsuarioPropio.controller('usuarioPropioAdminController', ['$scope', '$routeParams', '$location', 'usuariosAdminService', 'authenticationInfo', 'authenticationService', function ($scope, $routeParams, $location, usuariosAdminService, authenticationInfo, authenticationService) {

    $scope.authentication = authenticationInfo;
    $scope.usuarioNombre = $routeParams.usuario;

    usuariosAdminService.getUsuarioByName($routeParams.usuario)
            .then(function (response) {
                var data = response.data;
                $scope.usuario = data;
            });

    $scope.volver = function () {
        $location.path('/categorias');
    }

        usuariosAdminService.getRoles()
            .then(function (response) {
                var data = response.data;
                $scope.roles = data;
            });

        usuariosAdminService.getRolesPorUsuario($routeParams.id)
            .then(function (response) {
                var data = response.data;
                $scope.rolUsuario = data;
            });

    $scope.editarUsuario = function () {
            $scope.usuario.userName = $scope.usuarioNombre
            usuariosAdminService.putPropio($scope.usuario).then(function (response) {
                var result = response.data;
                alert(result);
                //$location.path("/admin/usuarios");

            }).catch(function (response) {
                $scope.error = usuariosAdminService.obtenerPrimerErrorModelState(response.data);
                alert($scope.error);
            });
        }


    }]);