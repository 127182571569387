//const { forEach } = require("lodash");

var app = angular.module('galibos');

app.controller('galibosController', ['$rootScope', '$scope', '$location', '$route', '$routeParams', 'galibosService', 'infraestructurasService', 'authenticationInfo', 'authenticationService',
    function ($rootScope, $scope, $location, $route, $routeParams, galibosService, infraestructurasService, authenticationInfo, authenticationService) {

        $scope.authentication = authenticationInfo;
        $scope.rol = authenticationInfo.rol;
        $scope.usuario = $rootScope.usuario;


        $scope.pestanyavisible = 'datosentrada';

        

        //VARIABLES PARA EL CONTORNO CINEMATICO DE REFERENCIA

        //var CCRefh1 = 0.4;
        //var CCRefh2 = 1.15;
        //var CCRefh3 = 1.15;
        //var CCRefh4 = 3.32;
        //var CCRefh5 = 3.32;
        //var CCRefh6 = 3.32;
        //var CCRefh7 = 4.545;


        //var CCRefb1 = 1.695;
        //var CCRefb2 = 1.695;
        //var CCRefb3 = 1.72;
        //var CCRefb4 = 1.72;
        //var CCRefb5 = 1.72;
        //var CCRefb6 = 1.72;
        //var CCRefb7 = 1.49;

        var par = 0.0;

        var Tq = 30;

        var b = 1.695;
        var h = 0.76;
        $scope.h = h;
        $scope.b = b;
        $scope.k = 0;
        $scope.S_0 = 0.4;

        $scope.a = 0;
        $scope.aqa = 1;
        $scope.bordeInt = 1;
        $scope.bordeExt = 1;
        $scope.Tq = Tq;
        $scope.TD = 1;

        $scope.Io = 0.066;
        $scope.Do = 0.066;

        galibosService.getEstadoBalasto()
            .then(function (response) {
                $scope.estadosbalasto = response.data;
            });

        galibosService.getContornos()
            .then(function (response) {
                var data = response.data;
                $scope.contornos = data;
            });

        $scope.imprimir = function () {

            galibosService.getDesplazamientosLateralesVerticales($scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)

                .then(function (response) {
                    $scope.galiboVerticalHorizontal = response.data;
                    alert('Cálculo de desplazamientos laterales verticales completado');

                    galibosService.getGaliboAnden($scope.a, $scope.aqa, $scope.bordeInt, $scope.bordeExt, $scope.Tq, $scope.datosparametrizables.Tvia, $scope.TD, $scope.datosparametrizables.Kgen,
                        $scope.I, $scope.datosparametrizables.I0, $scope.datosparametrizables.L, h, $scope.D, $scope.datosparametrizables.D0, $scope.R, $scope.datosparametrizables.lmax,
                        $scope.EstadoBalasto.Descripcion,
                        $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.datosparametrizables.hc0, b)

                        .then(function (response) {
                            $scope.galiboAnden = response.data;
                            alert('Cálculo de gálibo andén completado');

                            galibosService.getDesplazamientosNominal($scope.Io, $scope.Do, $scope.datosparametrizables.lmax, $scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)

                                .then(function (response) {
                                    $scope.galiboNominal = response.data;
                                    alert('Cálculo de desplazamiento nominal completado');

                                    // $scope.imprimir = function () {

                                    var datosentrada = new Object();

                                    datosentrada.ContornoInferior = $scope.ContornoInferior.Id;
                                    datosentrada.AnchoVia = $scope.AnchoVia.Id;
                                    datosentrada.Contorno = $scope.Contorno.Id;
                                    datosentrada.R = $scope.R;
                                    datosentrada.D = $scope.D;
                                    datosentrada.I = $scope.I;
                                    datosentrada.TD = $scope.TD;
                                    datosentrada.TN = $scope.TN;
                                    datosentrada.Rv = $scope.Rv;
                                    datosentrada.Tension = $scope.Tension.Id;
                                    datosentrada.Pantografo = $scope.Pantografo.Id;
                                    datosentrada.EstadoBalasto = $scope.estadosbalasto[0].Id;

                                    $scope.datosentrada = datosentrada;

                                    var formDataTexto = new FormData();
                                    formDataTexto.append("galiboVerticalHorizontal", JSON.stringify($scope.galiboVerticalHorizontal));
                                    formDataTexto.append("galiboNominal", JSON.stringify($scope.galiboNominal));
                                    formDataTexto.append("galiboAnden", JSON.stringify($scope.galiboAnden));

                                    //     galibosService.imprimir($scope.datosentrada, $scope.datosparametrizables, $scope.galiboVerticalHorizontal, $scope.galiboNominal, $scope.galiboAnden)

                                    galibosService.imprimir($scope.datosentrada, $scope.datosparametrizables, formDataTexto)
                                        .then(function (data, status, headers) {
                                            var filename = headers('prueba.doc');
                                            var contentType = 'application/msword';

                                            var blob = new Blob([data],
                                                { type: contentType });

                                            if (navigator.appVersion.toString().indexOf('.NET') > 0) //IE
                                                window.navigator.msSaveBlob(blob, filename);
                                            else //Resto
                                            {
                                                var downloadLink = document.createElement("a");
                                                var url = window.URL.createObjectURL(blob);
                                                downloadLink.setAttribute("target", "_blank");
                                                downloadLink.setAttribute("href", url);
                                                downloadLink.setAttribute("download", filename);
                                                document.body.appendChild(downloadLink);
                                                downloadLink.click();
                                                document.body.removeChild(downloadLink);
                                                //elemento.style.display = 'none';
                                            }

                                        })



                                });


                        });



                });
        }

        $scope.calcular = function () {

            galibosService.getDesplazamientosLateralesVerticales($scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)

                .then(function (response) {
                    $scope.galiboVerticalHorizontal = response.data;

                    galibosService.getGaliboAnden($scope.a, $scope.aqa, $scope.bordeInt, $scope.bordeExt, $scope.Tq, $scope.datosparametrizables.Tvia, $scope.TD, $scope.datosparametrizables.Kgen,
                        $scope.I, $scope.datosparametrizables.I0, $scope.datosparametrizables.L, h, $scope.D, $scope.datosparametrizables.D0, $scope.R, $scope.datosparametrizables.lmax,
                        $scope.EstadoBalasto.Descripcion,
                        $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.datosparametrizables.hc0, b)

                        .then(function (response) {
                            $scope.galiboAnden = response.data;

                            galibosService.getDesplazamientosNominal($scope.Io, $scope.Do, $scope.datosparametrizables.lmax, $scope.Contorno.Id, $scope.ContornoInferior.Id, $scope.TN, $scope.Rv,
                                $scope.datosparametrizables.M3balto, $scope.datosparametrizables.M3bbajo, $scope.datosparametrizables.M3halto,
                                $scope.datosparametrizables.M3hbajo, $scope.datosparametrizables.Kh05, $scope.datosparametrizables.Tvia, $scope.datosparametrizables.Kgen,
                                $scope.EstadoBalasto.Descripcion, $scope.datosparametrizables.ac, $scope.datosparametrizables.asusp, $scope.TD, $scope.datosparametrizables.I0,
                                $scope.I, $scope.D, $scope.datosparametrizables.D0, $scope.datosparametrizables.L, $scope.R, $scope.datosparametrizables.lmax, $scope.datosparametrizables.hc0)

                                .then(function (response) {
                                    $scope.galiboNominal = response.data;
                                    alert('Cálculo de desplazamiento nominal completado')

                                    var formDataTexto = new FormData();
                                    formDataTexto.append("galiboVerticalHorizontal", JSON.stringify($scope.galiboVerticalHorizontal));
                                    formDataTexto.append("galiboNominal", JSON.stringify($scope.galiboNominal));
                                    formDataTexto.append("galiboAnden", JSON.stringify($scope.galiboAnden));

                                    galibosService.dibujarGraficos(formDataTexto)
                                        .then(function (response) {
                                            alert('Graficos Dibujados')
                                        })

                                });

                            alert('Cálculo de gálibo andén completado')
                        });

                    alert('Cálculo de desplazamientos laterales verticales completado')

                });
        }


        galibosService.getContornosInferiores()
            .then(function (response) {
                var data = response.data;
                $scope.contornosinferiores = data;
            });

        galibosService.getAnchosVia()
            .then(function (response) {
                var data = response.data;
                $scope.anchosvia = data;
            });

        galibosService.getPantografo()
            .then(function (response) {
                var data = response.data;
                $scope.pantografos = data;
            });

        galibosService.getTension()
            .then(function (response) {
                var data = response.data;
                $scope.tensiones = data;
            });

        galibosService.getEstadoBalasto()
            .then(function (response) {
                var data = response.data;
                $scope.estadosbalasto = data;
            });

        galibosService.getDatosParametrizables($scope.usuario)
            .then(function (response) {
                var data = response.data;
                //$scope.datosparametrizables = data;
                $scope.datosparametrizables = data;
            });

        galibosService.getRol($scope.usuario, $scope.InfraestructuraId)
            .then(function (response) {
                $scope.permiso = response.data;
            });        

        $scope.indice = 0;


        $scope.volver = function () {
            $location.path('/infraestructura/' + $scope.InfraestructuraId);
            $rootScope.galibosUrl = true;
        }

        $scope.DatosEntradaCargar = function () {
            $scope.pestanyavisible = 'datosentrada';
        }

        $scope.DatosParametrizablesCargar = function () {
            $scope.pestanyavisible = 'datosparametrizables';
        }


        $scope.logout = function () {
            return authenticationService.logout();
        }


    }
]);